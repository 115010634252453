import { PrismicNextImage } from "@prismicio/next";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import style from "./PrincipesScientifiques.module.scss";

export type PrincipesScientifiquesProps =
  SliceComponentProps<Content.PrincipesScientifiquesSlice>;

const PrincipesScientifiques = ({
  slice,
}: PrincipesScientifiquesProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className={style.principes}>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className={style.title}>
              <PrismicRichText field={slice.primary.title} />
            </div>
            <div className="lk-flex lk-flex-smaltab-colomn space-between">
              {slice.items.map((item, index) => (
                <div className={style.item} key={index}>
                  <div className={style.feature}>
                    <PrismicNextImage
                      field={item.picto}
                      title={item.picto.alt ?? ""}
                    />
                  </div>
                  <div className={style.subtitle}>
                    <PrismicRichText field={item.subtitle} />
                  </div>
                  <div className={style.description}>
                    <PrismicRichText field={item.description} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrincipesScientifiques;
